import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Image from 'next/image';

import Flickity from "react-flickity-component";

import { useUser } from 'services/user';

import Button from 'components/Button';
import Heading from 'components/Heading';

import styles from './HomeCarousel.module.css';
import Link from 'components/Link';

function HomeCarousel({ slides }) {
  const carouselRef = useRef(null);

  const carouselOptions = {
    cellSelector: `.${styles.hero}`,
    groupCells: false,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    widePagination: true,
    cellAlign: 'left',
    contain: true,
    autoPlay: 5000,
    adaptiveHeight: false,
    imagesLoaded: false
  }

  useEffect(() => {
    const current = carouselRef.current;

    if(current && slides && slides.length > 0) {
      current.reloadCells();
      current.resize();
      current.on('dragStart', () => {
        current.slider.style.pointerEvents = 'none';
        document.ontouchmove = () => false;
      });
      current.on('dragEnd', () => {
        current.slider.style.pointerEvents = 'auto';
        document.ontouchmove = () => true;
      });

      current.resize();
    }
  }, [slides]);

  return (
    <Flickity
      flickityRef={() => carouselRef}
      className={styles.carousel}
      elementType={'div'}
      options={carouselOptions}
      disableImagesLoaded={true}
      reloadOnUpdate
    >
      {slides && slides.map((hero, index) => {
        return (
          <div className={styles.hero} key={`hero${index}`}>
            <div className={styles['hero-mobile']}>
              <Image
                src={hero.image_mobile}
                alt={hero.title}
                layout="fill"
                objectFit="cover"
                objectPosition={`center`}
                quality="96"
                sizes={`100vw`}
                priority
                // {...(index == 0) ? {onLoadingComplete: () => console.log('loading done')} : {}}
              />
            </div>
            <div className={styles['hero-desktop']}>
              <Image
                src={hero.image}
                alt={hero.title}
                layout="fill"
                objectFit="cover"
                objectPosition={`center`}
                quality="96"
                sizes={`100vw`}
                priority
                // {...(index == 0) ? {onLoadingComplete: () => console.log('loading done')} : {}}
              />
            </div>
          </div>
        );
      })}
    </Flickity>
  );
}

HomeCarousel.propTypes = {
  slides: PropTypes.array.isRequired,
}

export default HomeCarousel;
