import Image from 'next/image';
import Player from 'react-player/vimeo';

import { getMediaByQuery } from 'lib/algolia';
import HomeService from 'services/home';

import styles from 'styles/Home.module.css'

import HeroMobile1 from 'public/fixtures/home-mobile-hero1.jpg';
import Hero1 from 'public/fixtures/home-hero1.jpg';
import HeroMobile2 from 'public/fixtures/home-mobile-hero2.jpg';
import Hero2 from 'public/fixtures/home-hero2.jpg';
import HeroMobile3 from 'public/fixtures/home-mobile-hero3.jpg';
import Hero3 from 'public/fixtures/home-hero3.jpg';
import InterviewImage from 'public/fixtures/walbeck-interview.jpg';
import CardsImage from 'public/fixtures/home-cards.png';

import AppLayout from 'components/AppLayout'
// import Sections from 'components/Sections';
import Heading from 'components/Heading';
// import Button from 'components/Button';
// import Video from 'components/Video';
// import Carousel from 'components/Carousel';

import DesktopHero from 'public/fixtures/hero.jpg';
import Sections from 'components/Sections';
import HomeCarousel from 'components/HomeCarousel';
import Button from 'components/Button';

const hero_slides = [
  {
    image: Hero1,
    image_mobile: HeroMobile1,
  },
  {
    image: Hero2,
    image_mobile: HeroMobile2,
  },
  {
    image: Hero3,
    image_mobile: HeroMobile3,
  },
];

const points = [
  {
    "image": "/runner.png",
    "title": "MLB",
    "text": "11 seasons as a switch-hitting catcher"
  },
  {
    "image": "/trophy.png",
    "title": "Winning",
    "text": "3 champion seasons"
  },
  {
    "image": "/whistle.png",
    "title": "Coaching",
    "text": "4-time minor league manager of the year"
  }
];

export default function Home({sections}) {
  return (
    <AppLayout basicFooter={false} navCta={true}>
      <div className={styles.home}>
        <div className={styles.hero}>
          <div className={styles['hero-content']}>
            <div className={styles['hero-container']}>
              <Heading element={`h1`} size={`h2`} className={styles['hero-title']}>{`Baseball Training Videos To Help You Coach Your Player`}</Heading>
              <p className={styles['hero-text']}>{`We provide parents and coaches with hundreds of training videos proven to develop players across all aspects of the game.`}</p>

              <div className={styles['hero-cta']}>
                <Button href="#featuredFree" button primary>Watch Free Training Videos</Button>
              </div>
            </div>
          </div>

          <div className={styles['hero-slides']}>
            <HomeCarousel slides={hero_slides} />
          </div>
        </div>
        <div className={styles.all}>
          <div className="container">
            <div className={styles['all-wrapper']}>
              <div className={styles.image}>
                <Image src={InterviewImage} />
              </div>
              <div className={styles['all-content']}>
                <Heading element={`h2`} size={`h4-5`} className={styles['video-title']}>{`All Training Videos Created by MLB Veteran, Matt Walbeck`}</Heading>

                <p className={styles['all-text']}>
                  {`Matt Walbeck is a 12-year MLB veteran player and coach. He's also trained individual players of all ages for over a decade.`}
                </p>

                <div className={styles['all-cta-desktop']}>
                  <Button href={`/matts-career`} button primary centered>{`Explore Matt's MLB Career`}</Button>
                </div>
              </div>
            </div>
            <div className={styles['all-cards']}>
              <Image src={CardsImage} layout={`responsive`} />
            </div>

            <div className={styles['all-cta-mobile']}>
              <Button href={`/matts-career`} button primary centered>{`Explore Matt's MLB Career`}</Button>
            </div>
          </div>
        </div>

      {sections && 
        <div id="featuredFree">
          <Sections sections={sections} />
        </div>
      }
      </div>
    </AppLayout>
  )
}

export async function getStaticProps() {
  const response = await HomeService.getHomeData();

  const getSectionData = async () => {
    return Promise.all(response.data.sections.map(async (section) => {
      if(section.type == 'carousel' || section.type == 'stacked_carousel') {
        let data = section.data;
        const cards = await getMediaByQuery(section.data.query);
        data.cards = cards;
        return {
          ...section,
          data: data,
          
        }
      }
      else {
        return section;
      }
    }))
  }

  const sections = await getSectionData();

  return {
    props: {
      sections,
      seo: {
        share_image: '/share.jpg'
      }
    },
    revalidate: 60,
  }  
}
