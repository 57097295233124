import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import dynamic from 'next/dynamic'

import styles from './Sections.module.css';
let cx = classnames.bind(styles);

const Proof = dynamic({loader: () => import(/* webpackChunkName: 'Proof' */'./Proof')});
const ProofNew = dynamic({loader: () => import(/* webpackChunkName: 'ProofNew' */'./ProofNew')});
const Unlock = dynamic({loader: () => import(/* webpackChunkName: 'Unlock' */'./Unlock')});
const Content = dynamic({loader: () => import(/* webpackChunkName: 'Content' */'./Content')});
const Faq = dynamic({loader: () => import(/* webpackChunkName: 'Faq' */'./Faq')});
const CategoryButtons = dynamic({loader: () => import(/* webpackChunkName: 'CategoryButtons' */'./CategoryButtons')});
const PlaylistGrid = dynamic({loader: () => import(/* webpackChunkName: 'PlaylistGrid' */'./PlaylistGrid')});
const Carousel = dynamic({loader: () => import(/* webpackChunkName: 'Carousel' */'../Carousel')});

function Sections({sections}) {
  return (
    <div className={styles['sections']}>
      {sections.map((section, index) => {
        const slug_type = section.type && section.type.replace(/_/g, '-');
        const classes = cx(
          'section',
          `section-${slug_type}`,
          {
            'category-light': section.data.categoryLight,
            'blue-splatter': section.data.blueSplatter,
          }
        );

        return (
          <div className={classes} key={index} style={(section.data?.bg_color) ? {'--bg-color': section.data?.bg_color} : {}}>
            {(function() {
              switch (section.type) {
                case 'proof':
                  return <Proof {...section.data} />;
                case 'proof_new':
                  return <ProofNew {...section.data} />;
                case 'unlock':
                  return <Unlock {...section.data} />;
                case 'content':
                  return <Content {...section.data} />;
                case 'faq':
                  return <Faq {...section.data} />;
                case 'carousel':
                  return <Carousel {...section.data} />;
                case 'stacked_carousel':
                  return <Carousel {...section.data} />;
                case 'playlist_grid':
                  return <PlaylistGrid {...section.data} />;
                case 'category_buttons':
                  return <CategoryButtons {...section.data} />;
                default:
                  return null;
              }
            })()}
          </div>
        );    
      })}
    </div>
  );
}

Sections.propTypes = {
  sections: PropTypes.array.isRequired,
}

export default Sections;